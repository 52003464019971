import { Button } from '@withdiver/components/src/Button'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback, useEffect, useState } from 'react'
import { Menu } from 'react-feather'
import styled from 'styled-components'

const Link = styled(TextLink).attrs({
	color: 'text',
	mr: 60,
})({})

function useSticky() {
	const [ hasScrolled, setHasScrolled ] = useState(window.scrollY > 0)

	const handleScroll = useCallback(() => {
		setHasScrolled(window.scrollY > 0)
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [ handleScroll ])

	return hasScrolled
}

interface MainMenuProps {
	stick: boolean
}

function MainMenu({ stick }: MainMenuProps) {
	const onLoginClick = useCallback(() => {
		window.location.href = 'https://dashboards.withdiver.com'
	}, [])

	return (
		<View
			alignItems="center"
			backdropFilter={stick ? 'blur(200px)' : undefined}
			backgroundColor={stick ? 'rgb(255, 255, 255, 0.1)' : undefined}
			borderRadius={9999}
			height={70}
			position="sticky"
			px={20}
			top={stick ? 20 : 0}
			width="100%"
			zIndex={2}
		>
			<img src="/logo.svg" width={40} style={{ margin: '5px' }} alt=""/>
			<Text color="text" fontSize={30}>diver</Text>
			<View flexGrow={1}/>
			<View
				alignItems="inherit"
				display={['none', 'none', 'inherit']}
			>
				<Link to="">
					Product
				</Link>
				<Link to="">
					Pricing
				</Link>
				<Link to="">
					Docs
				</Link>
				<Link to="">
					About us
				</Link>
				<Button
					size="medium"
					variant="outline"
					mr={1}
					onClick={onLoginClick}
				>
					Log in
				</Button>
				<Button
					size="medium"
					variant="primary"
				>
					Sign up
				</Button>
			</View>
			<View
				display={['inherit', 'inherit', 'none']}
				color="white"
			>
				<OverflowActionMenu icon={Menu}>
					<View
						m={1}
						width="70vw"
						maxWidth={400}
						flexDirection="column"
						gap={20}
					>
						<Link to="">
							Product
						</Link>
						<Link to="">
							Pricing
						</Link>
						<Link to="">
							Docs
						</Link>
						<Link to="">
							About us
						</Link>
						<View>
							<Button
								size="medium"
								variant="outline"
								mr={3}
								flexGrow={1}
								onClick={onLoginClick}
							>
								Log in
							</Button>
							<Button
								size="medium"
								variant="primary"
								flexGrow={1}
							>
								Sign up
							</Button>
						</View>
					</View>
				</OverflowActionMenu>
			</View>
		</View>
	)
}

export {
	MainMenu,
	useSticky,
}
