import styled from 'styled-components'
import { View, ViewProps } from '../View'
import { ModalBackdropProps } from './index'

const ModalBackdrop = styled(View)<ViewProps & ModalBackdropProps>`
    all: inherit;
    inset: 0;
    overflow-x: hidden;
    transition: all 0.15s;
    filter: ${props => props.active ? props.theme.modal.backdrop.filter : undefined};
    pointer-events: ${props => props.active ? 'none' : undefined};
`

export default ModalBackdrop
