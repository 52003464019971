import styled from 'styled-components'
import { variant } from 'styled-system'
import Theme from '../theme/Theme'
import { View, ViewProps } from '../View'

export interface ButtonProps extends ViewProps {
	size: keyof Theme['button']['sizes']
	variant: keyof Theme['button']['variants']
	disabled?: HTMLButtonElement['disabled']
	type?: HTMLButtonElement['type']
}

export const Button = styled(View).attrs({ as: styled.button`` })<ButtonProps>(
	{
		border: '2px solid',
		alignItems: 'center',
	},
	variant({
		prop: 'size',
		scale: 'button.sizes',
	}),
	variant({
		scale: 'button.variants',
	}),
	(({ disabled }) => ({
		opacity: disabled ? 0.4 : 1,
	}))
)

Button.defaultProps = {
	size: 'medium',
}
