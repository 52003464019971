import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React, { PropsWithChildren } from 'react'

interface CircleStepProps extends PropsWithChildren {
	color: keyof Theme['colors']
}

function CircleStep({ children, color }: CircleStepProps) {
	return (
		<View
			size="16rem"
			position="relative"
			justifyContent="center"
			alignItems="center"
			mx="auto"
			flexDirection="column"
		>
			<View
				borderRadius="50%"
				backgroundColor={color}
				opacity={0.2}
				position="absolute"
				inset="0"
			/>
			<View
				borderRadius="50%"
				position="absolute"
				inset={28}
				backgroundColor={color}
				opacity={0.4}
				flexDirection="column"
			>
			</View>
			{children}
		</View>
	)
}

export {
	CircleStep,
}
