import React from 'react'
import styled, { keyframes, useTheme } from 'styled-components'
import { zIndex, ZIndexProps } from 'styled-system'
import { Card } from '../Card'
import Theme from '../theme/Theme'
import { ViewProps } from '../View'
import { ModalProps } from './index'

const appear = keyframes`
	from {
		margin-top: -50px;
		opacity: 0;
	}
	to {
		margin-top: 0;
		opacity: 1;
	}
`

const Container = styled.div<ZIndexProps>`
    & > * {
        animation: ${appear} 0.3s ease forwards;
    }
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    ${zIndex}
`

function Modal({ children, zIndex, ...props }: ModalProps & ViewProps) {
	const theme = useTheme() as Theme
	return <Container zIndex={zIndex}>
		<Card
			{...props}
			boxShadow={theme.modal.boxShadow}
		>
			<Card.Content p={20} height="inherit" width="inherit">
				{children}
			</Card.Content>
		</Card>
	</Container>
}

export default Modal
