import React from 'react'
import { useOnClickOutside } from '../utils/useOnClickOutside'
import { View } from '../View'
import {
	ModalArray,
	ModalProps,
} from './index'

interface TModalContext {
	modals: ModalArray
	setModals: React.Dispatch<React.SetStateAction<TModalContext['modals']>>
	zIndex: number
}

export const ModalContext = React.createContext<TModalContext|undefined>(undefined)

interface UseModal {
	show: (fun: (props: ModalProps) => React.ReactElement) => () => void
	hide: () => void
	zIndex: number
}

function handleKeyDown(callback: () => void) {
	return (event: KeyboardEvent): void => {
		if (event.key === 'Escape') {
			callback()
		}
	}
}

function useModal(): UseModal {
	const context = React.useContext(ModalContext)

	if (context === undefined) {
		throw new Error('No context')
	}

	const [ index ] = React.useState<number>(context.modals.length)

	const {
		modals,
		setModals,
		zIndex,
	} = context

	React.useEffect(() => {
		setModals([
			...modals,
			false,
		])
	}, [ index ])

	const hide = React.useCallback(() => {
		setModals(modals.map((modal, i) => i !== index ? modal : false))
	}, [ index, modals, setModals ])

	React.useEffect(() => {
		const keyDownListener = handleKeyDown(hide)
		window.document.addEventListener('keydown', keyDownListener)
		return () => {
			window.document.removeEventListener('keydown', keyDownListener)
		}
	}, [ hide, modals ])

	const ref = useOnClickOutside<HTMLElement>(hide)

	const show = React.useCallback((modal: React.FunctionComponent<ModalProps>) => {
		return () => {
			console.log(index)
			const Modal = modal({ onHide: hide, zIndex })
			setModals(modals.map((V, i) => {
				if (i !== index) {
					return false
				}

				return (
					<React.Fragment key={i}>
						{i === index &&
							<View ref={ref}>
								{Modal}
							</View>
						}
					</React.Fragment>
				)
			}))
		}
	}, [ hide, index, modals, setModals, zIndex])

	return {
		show,
		hide,
		zIndex,
	}
}

export default useModal
