import { Button } from '@withdiver/components/src/Button'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React from 'react'
import { CheckCircle, ChevronRight, Terminal, UploadCloud } from 'react-feather'
import styled from 'styled-components'
import { BreathingOrbs } from './BreathingOrbs'
import { CircleStep } from './CircleStep'
import { DashboardPreview } from './DashboardPreview'
import { MainMenu, useSticky } from './MainMenu'

const introLineHeight = 1

const Accent = styled(Text).attrs({
	color: 'primary',
	display: 'initial',
})({
	lineHeight: introLineHeight
})

const intros = [
	<><Accent>Explore</Accent> your data in new ways</>,
	/* Hide this because it takes up three lines and ruins the feeling of the frontpage.
	<>Get a <Accent>deeper</Accent> understanding of your business</>,
	*/
	<>Dive <Accent>headfirst</Accent> into your data</>,
	<>Add <Accent>depth</Accent> to your metrics</>,
]

const intro = intros[Math.floor(Math.random() * 100) % intros.length]

function Frontpage() {
	const stick = useSticky()

	return (
		<View
			minWidth="100%"
			minHeight="400vh"
			backgroundColor="black"
			flexDirection="column"
			position="relative"
		>
			<BreathingOrbs/>
			<View
				marginX="auto"
				maxWidth={1200}
				minHeight="inherit"
				width="100%"
				flexDirection="column"
				zIndex={1}
			>
				<MainMenu stick={stick}/>
				<View
					justifySelf="center"
					maxWidth="80%"
					mt="15vh"
					mx="auto"
				>
					<Text
						color="text"
						display="block"
						fontSize={80}
						fontWeight={500}
						lineHeight={introLineHeight}
						textAlign="center"
						textWrap="balance"
					>
						{intro}
					</Text>
				</View>
				<View
					mt="8vh"
					justifyContent="center"
				>
					<Button size="medium" variant="primary">
						Get started <ChevronRight size="1em"/>
					</Button>
				</View>
				<View
					mt="7vh"
					justifyContent="center"
					pt="1px" // This is required for the 1px box-shadow on the dashboard preview.
				>
					<DashboardPreview/>
				</View>
				<View
					flexDirection="column"
					gap={100}
					mt={150}
				>
					<CircleStep color="info">
						<Text color="text"><Terminal size={28}/></Text>
						<Text color="text" fontSize={4}>Develop</Text>
					</CircleStep>
					<CircleStep color="danger">
						<Text color="text"><UploadCloud size={28}/></Text>
						<Text color="text" fontSize={4}>Preview</Text>
					</CircleStep>
					<CircleStep color="success">
						<Text color="text"><CheckCircle size={28}/></Text>
						<Text color="text" fontSize={4}>Publish</Text>
					</CircleStep>
				</View>
			</View>
			<View my={50} mx="auto" zIndex={1}>
				<TextLink color="text" to="">
					Made with 🫧 by the Diver team
				</TextLink>
			</View>
		</View>
	)
}

export default Frontpage
