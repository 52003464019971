import React from 'react'
import { useTheme } from 'styled-components'
import Theme from '../theme/Theme'
import { View, ViewProps } from '../View'

function Card(props: ViewProps) {
	const theme = useTheme() as Theme
	return (
		<View
			backdropFilter={theme.dashboardItem.backdropFilter}
			backgroundColor={theme.dashboardItem.backgroundColor}
			borderRadius={10}
			{...props}
		/>
	)
}

function CardContent(props: ViewProps) {
	return <View {...props}/>
}

Card.Content = CardContent

export {
	Card
}
