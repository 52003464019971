import React from 'react'
import styled, { useTheme } from 'styled-components'
import Theme from '../theme/Theme'
import { View } from '../View'

interface StickyCellProps {
	location: 'top' | 'bottom'
}

const StickyCell = styled.th<StickyCellProps>(
	{
		fontWeight: 600,
		position: 'sticky',
		textAlign: 'left',
	},
	({ location }) => ({
		[location]: 0,
	}),
	({ theme }) => ({ backgroundColor: theme.dashboardItem.backgroundColor }),
	({ theme }) => ({ color: theme.dashboardItem.stickyColor }),
)

interface TableChartProps {
	data: {
		columns: string[]
		rows: any[][]
	}
	renderSettings?: any
}

function TableChart(props: TableChartProps) {
	const shouldSum = false
	const theme = useTheme() as Theme

	return (
		<table style={{ height: 'fit-content', width: '100%' }}>
			<thead>
			<tr>
				{props.data.columns.map((column, index) => (
					props.renderSettings?.columns?.[index]?.visibility !== 'hidden' &&
					<StickyCell key={index} location="top">
						{column.charAt(0).toUpperCase() + column.slice(1).replace('_', ' ')}
					</StickyCell>
				))}
			</tr>
			</thead>
			<tbody>
			{props.data.rows.map((row, i) => (
				<tr key={i}>
					{row.map((cell, j) => (
						props.renderSettings?.columns?.[j]?.visibility !== 'hidden' &&
						<td key={j} className={props.renderSettings?.columns?.[j]?.className}>
							{cell?.toString() ?? <i><View color={theme.dashboardItem.nullColor}>NULL</View></i>}
						</td>
					))}
				</tr>
			))}
			</tbody>
			{/* SUMMATION ROW */}
			{shouldSum &&
			<tfoot>
			<tr>
				{props.data.rows[0]?.map((column, index) => (
					<StickyCell key={index} location="bottom">
						{column?.toString()}
					</StickyCell>
				))}
			</tr>
			</tfoot>
			}
		</table>
	)
}

export {
	TableChart,
}
