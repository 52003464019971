import React, { useState } from 'react'
import { MoreHorizontal } from 'react-feather'
import { Card } from '../../Card'
import { useOnClickOutside } from '../../utils/useOnClickOutside'
import { View } from '../../View'

interface OverflowActionMenuProps extends React.PropsWithChildren {
	icon?: any
	size?: number
}

export function OverflowActionMenu({ children, icon, size }: OverflowActionMenuProps) {
	const [ open, setOpen ] = useState<boolean>(false)
	const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false))
	return (
		<View flexDirection="column" position="relative">
			<View onClick={() => setOpen(!open)} alignSelf="flex-end" cursor="pointer">
				<View as={icon ?? MoreHorizontal} size={size ?? 20}/>
			</View>
			{open &&
			<View ref={ref}>
				<Card
					elevation={2}
					zIndex={200}
					position="absolute"
					top={16}
					right={0}
					padding={12}
					marginTop={1}
					flexDirection="column"
					gap="10px"
					width="max-content"
				>
					{children}
				</Card>
			</View>
			}
		</View>
	)
}
