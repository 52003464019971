import { View, ViewProps } from '@withdiver/components/src/View'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { CSSObject, keyframes } from 'styled-components'

function makeNewPosition() {
	const e = window.innerHeight
	const t = window.innerWidth
	const n = .25 * Math.random()
	const r = .35 * Math.random() + .4
	return {
		top: Math.floor(n * e),
		left: Math.floor(r * t),
	}
}

const k = keyframes`
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-70vh);
	}
`

interface OrbContainerProps {
	$top?: CSSObject['top']
}

const OrbContainer = styled(View).attrs<OrbContainerProps>(({ $top }) => ({
	style: {
		top: $top,
	}
}))<OrbContainerProps>`
	height: 100vh;
	position: fixed;
	animation: ${k} 1s linear both;
	animation-play-state: paused;
	animation-delay: calc(var(--scroll) * -1s);
`

interface OrbAttrs {
	backgroundColor: ViewProps['backgroundColor']
	$transform: CSSObject['transform']
}

const Orb = styled(View).attrs<OrbAttrs>(({ $transform }) => ({
	style: {
		transform: $transform,
	},
}))<OrbAttrs>(
	{
		borderRadius: '50%',
		height: 750,
		pointerEvents: 'none',
		position: 'absolute',
		transition: 'transform 3.5s ease-in-out',
		width: 800,
		transform: 'none',
	},
)

function BreathingOrbs() {
	const [ position1, setPosition1 ] = useState({ top: 0, left: 0 })
	const [ position2, setPosition2 ] = useState({ top: 0, left: 0 })
	const [ position3, setPosition3 ] = useState({ top: 0, left: 0 })

	useEffect(() => {
		const intervals: { [key: string]: ReturnType<typeof setInterval> | null } = {}
		const timeouts: { [key: string]: ReturnType<typeof setTimeout> | null } = {}
		setPosition1(makeNewPosition())
		setPosition2(makeNewPosition())
		setPosition3(makeNewPosition())

		intervals.int1 = setInterval(() => setPosition1(makeNewPosition()), 3600)
		timeouts.time1 = setTimeout(
			() => intervals.int2 = setInterval(() => setPosition2(makeNewPosition()), 3600),
			2000,
		)
		intervals.int3 = setInterval(() => setPosition3(makeNewPosition()), 3600)
		return () => {
			clearTimeout(timeouts.time1!)
			clearInterval(intervals.int1!)
			clearInterval(intervals.int2!)
			clearInterval(intervals.int3!)
		}
	}, [])

	const handleScroll = useCallback(() => {
		document.body.style.setProperty(
			'--scroll',
			String(window.scrollY / (document.body.offsetHeight - window.innerHeight)),
		);
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [ handleScroll ])

	return (
		<OrbContainer>
			<View filter="blur(120px)">
				<Orb
					backgroundColor="#002375"
					$transform={`translate(${position1.left}px, ${position1.top}px)`}
				/>
				<Orb
					backgroundColor="#510095"
					$transform={`translate(${position2.left}px, ${position2.top}px)`}
				/>
				<Orb
					backgroundColor="#000924"
					$transform={`translate(${position3.left}px, ${position3.top}px)`}
				/>
			</View>
		</OrbContainer>
	)
}

export {
	BreathingOrbs,
}
