import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dark from '@withdiver/components/src/theme/dark'
import { GlobalStyle } from '@withdiver/components/src/theme/GlobalStyle'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import Frontpage from './pages/Frontpage'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
})

tippy.setDefaultProps({ duration: 150 })

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={dark}>
				<GlobalStyle/>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Frontpage/>}/>
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</QueryClientProvider>
	)
}

export default App
