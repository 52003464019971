import React, { ReactNode, useState } from 'react'
import { ModalContext } from './useModal'
import ModalBackdrop from './ModalBackdrop'
import { ModalArray } from './index'

interface ModalHandlerProps {
	children?: ReactNode
	zIndex: number
}

function ModalHandler({ children, zIndex }: ModalHandlerProps) {
	const [ modals, setModals ] = useState<ModalArray>([])

	const modalContext = {
		modals,
		setModals,
		zIndex,
	}

	const disabled = Boolean(modals.filter(value => value).length)

	return (
		<ModalContext.Provider value={modalContext}>
			<ModalBackdrop active={disabled}>
				{children}
			</ModalBackdrop>
			{modals}
		</ModalContext.Provider>
	)
}

export default ModalHandler
