import React, { ForwardedRef } from 'react'
import { Chart as BaseChart } from 'react-chartjs-2'
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartData,
	ChartOptions,
	ChartType,
	Legend,
	LinearScale,
	LineElement,
	Plugin,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js'
import { useTheme } from 'styled-components'
import Theme from '../theme/Theme'

ChartJS.defaults.font.family = 'Inter'

ChartJS.register(
	ArcElement,
	BarElement,
	CategoryScale,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
)

const COLORS = [
	'#236cf7',
	'#c800e9',
	'#ff8132',
	'#01c062',
	'#ffd13d',
	'#e81a88',
]

export function color(index: number) {
	return COLORS[index % COLORS.length]
}

export interface ChartProps<T extends ChartType = ChartType> {
	data: ChartData<T>
	title?: string
	type: T
	options?: ChartOptions<T>
	plugins?: Plugin[]
	renderSettings?: any
}

export const Chart = React.forwardRef((props: ChartProps, ref: ForwardedRef<any>) => {
	const theme = useTheme() as Theme
	return (
		<BaseChart
			ref={ref}
			{...props}
			options={{
				color: theme.dashboardItem.color,
				scales: {
					x: {
						stacked: props.renderSettings?.stacked ?? false,
						border: {
							dash: [8, 4],
						},
						grid: {
							display: false,
							offset: true,
						},
						ticks: {
							color: theme.dashboardItem.stickyColor,
						},
					},
					y: {
						stacked: props.renderSettings?.stacked ?? false,
						border: {
							dash: [8, 4],
						},
						grid: {
							// color: '#ffffff22',
							// borderColor: 'white',
							display: true,
							drawTicks: false,
						},
						ticks: {
							callback: (value) => {
								value = Number(value)
								const absoluteValue = Math.abs(value)
								if (absoluteValue >= 1000000000) {
									return value / 1e9 + 'B'
								}
								if (absoluteValue >= 1000000) {
									return value / 1e6 + 'M'
								}
								if (absoluteValue >= 1000) {
									return value / 1e3 + 'K'
								}
								return value
							},
							color: theme.dashboardItem.stickyColor,
						},
					},
				},
				maintainAspectRatio: false,
				responsive: true,
				plugins: {
					title: {
						color: theme.dashboardItem.stickyColor,
						display: Boolean(props.title),
						text: props.title,
					},
				},
				...props.options,
			}}
			plugins={props.plugins}
		/>
	)
})
