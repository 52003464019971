import { darken } from 'polished'
import Theme from './Theme'

const sizes: Theme['sizes'] = [ 8, 12, 16, 24, 35 ]
const space: Theme['space'] = [ 0, 10, 20, 30, 40 ]

const colors: Theme['colors'] = {
	text: '#FFFFFF',
	primary: '#6A4EF5',
	info: '#6BC8F5',
	success: '#38F591',
	warning: '#F5E045',
	danger: '#F54D3B',
	cardInputBackground: '#2B344166',
	rootBackgroundColor: '#050F1E',
	window: {
		close: '#FF5F56',
		fullscreen: '#27C93F',
		minimize: '#FFBD2E',
	},
}

const shadows: Theme['shadows'] = [
	'0 1px 3px 0px rgba(0,0,0,0.05)',
	'0 1px 3px 0px rgba(0,0,0,0.15)',
	'0 1px 3px 4px rgba(0,0,0,0.05)',
]

const theme: Theme = {
	shadows,
	sizes,
	space,
	colors,
	button: {
		sizes: {
			icon: {
				borderRadius: '5000px', // Percentages (e.g. 50%) render weirdly in Firefox
				padding: 2,
			},
			medium: {
				height: 40,
				borderRadius: 20,
				padding: `0 ${space[2]}px`,
			},
		},
		variants: {
			primary: {
				'&:hover:enabled': {
					background: darken(0.05, colors.primary),
				},
				'&:active:enabled': {
					background: darken(0.15, colors.primary),
				},
				borderColor: 'transparent',
				background: colors.primary,
				color: 'white',
			},
			outline: {
				'&:hover:enabled': {
					color: darken(0.1, colors.primary),
				},
				'&:active:enabled': {
					color: darken(0.3, colors.primary),
				},
				color: colors.primary,
				background: 'transparent',
			},
			success: {
				'&:hover:enabled': {
					background: darken(0.1, colors.success),
				},
				'&:active:enabled': {
					background: darken(0.3, colors.success),
				},
				borderColor: 'transparent',
				background: colors.success,
				color: 'white',
			},
			danger: {
				'&:hover:enabled': {
					background: darken(0.1, colors.danger),
				},
				'&:active:enabled': {
					background: darken(0.3, colors.danger),
				},
				borderColor: 'transparent',
				background: colors.danger,
				color: 'white',
			},
		},
	},
	chart: {
		gauge: {
			dataBackgroundColor: '#1f3f70',
			needleColor: 'white',
			needleValueColor: 'white',
		},
	},
	codeArea: {
		colors: {
			comment: colors.info,
			constant: '#B7B6CA',
			doc: colors.info,
			dql: colors.primary,
			keyword: '#06F0C6',
			string: '#E6DB74',
			supportFunction: '#06F0C6',
		},
	},
	dashboard: {
		backgroundColor: '#06152B',
	},
	dashboardItem: {
		backdropFilter: 'blur(80px) brightness(200%)',
		backgroundColor: 'rgba(33, 39, 47, 0.31)',
		borderColor: 'transparent',
		color: 'white',
		nullColor: '#777777',
		stickyColor: '#89abc5',
	},
	input: {
		backdropFilter: 'blur(20px)',
		border: '2px solid #4D5C72',
	},
	modal: {
		backdrop: {
			filter: 'brightness(50%)',
		},
		boxShadow: '#060606 0px 10px 50px',
	}
}

export default theme
